import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "../css/Visuals.css";
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400.
import "@fontsource/source-sans-pro";
import "@fontsource/roboto";
import "../css/DayViewAggregate.css";
import { Icon } from "semantic-ui-react";

/* Day View Model Imports */
import HeartRate from "./DayViewModels/HeartRate";
import Sleep from "./DayViewModels/Sleep";
import Steps from "./DayViewModels/Steps";
import AlgorithmAlerts from "./DayViewModels/AlgorithmAlerts";

/* You are going to needs constructors to build the views from the passed days from month view */
function DayviewAggregate(props) {
  {
    /* Show and Close Visualization on Click of chart */
  }
  const [showVisualizationDayView, setShowVisualizationDayView] =
    useState(true);
  const handleCloseVisualizationDayView = () =>
    setShowVisualizationDayView(false);
  const handleShowVisualizationDayView = () =>
    setShowVisualizationDayView(true);

  const [showExpandedDayView, setShowExpandedDayView] = useState(true);
  const handleShowExpandedDayViewHR = () =>
    setShowExpandedDayView(<HeartRate />);
  const handleShowExpandedDayViewSl = () => setShowExpandedDayView(<Sleep />);
  const handleShowExpandedDayViewSt = () => setShowExpandedDayView(<Steps />);
  const handleShowExpandedDayViewAA = () =>
    setShowExpandedDayView(<AlgorithmAlerts />);

  return (
    <>
      <div className="visualSpacing">
        <div className="tileContainer">
          <div className="topRow">
            <a
              className="tileA"
              onClick={() => {
                handleShowVisualizationDayView();
                handleShowExpandedDayViewHR();
              }}
            >
              <HeartRate hrData={props.hrData}/>
            </a>
            <a
              className="tileB"
              onClick={() => {
                handleShowVisualizationDayView();
                handleShowExpandedDayViewSl();
              }}
            >
              <Sleep  slData={props.slData}/>
            </a>
          </div>
          <div className="bottomRow">
            <a
              className="tileA"
              onClick={() => {
                handleShowVisualizationDayView();
                handleShowExpandedDayViewSt();
              }}
            >
              <Steps  stData={props.stData}/>
            </a>
            <a
              className="tileB"
              onClick={() => {
                handleShowVisualizationDayView();
                handleShowExpandedDayViewAA();
              }}
            >
              <AlgorithmAlerts alertData={props.alertData}/>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default DayviewAggregate;


