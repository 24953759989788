import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { initSessionFromCallbackURI } from '../actions/session'

function mapStateToProps (state) {
  return { session: state.session }
}
function mapDispatchToProps (dispatch) {
  return {
    initSessionFromCallbackURI: href => dispatch(initSessionFromCallbackURI(href))
  }
}

/**
  Callback route used after a successful Cognito sign-in. The window URL will contain the code we can
  use to get a Cognito session, which includes JWT tokens etc
 */
class Callback extends Component {
  // If a Cognito auth code is in the URL (could be a hash or query component), init the new session

  componentDidMount () {
    let code = this.props.searchParams && this.props.searchParams.get('code')

    if (code) {
      this.props.initSessionFromCallbackURI(window.location.href)
    }
  }

  render () {
    let code = this.props.searchParams && this.props.searchParams.get('code')
    
    // If there's no auth code in the URL or we're now logged into, redirect to the root page
    if (!code  || this.props.session.isLoggedIn) {
      return <Navigate to="/" />
    }

    return <div />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Callback)
