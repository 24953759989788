import React from "react";
import DashboardLayout from "../components/DashboardLayout";
import {
  Header,
  Divider,
  Icon,
  Table
} from "semantic-ui-react";

/* Link Component import */
import { Link } from "react-router-dom";

/* RC-Pagination Import */
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";

/* Styling */
import './css/Studies.css' 
import "rc-pagination/assets/index.css";
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400.  
import "@fontsource/source-sans-pro";
import "@fontsource/roboto"; 

/* Remove weird Korean Symbols */
import locale from 'rc-pagination/es/locale/en_US';

/* Column Names for the top of the table, only needs Study name to be dynamic */
const tableHead = {
  name: "Study Name"
};

const Studies = () => {
  /* Value declaration for Pagination Max values */
  const countPerPage = 8;

  /* Value Declaration of the value for the Search function */
  const [value, setValue] = React.useState("");
  
  /* Value Declaration for the current Page for pagination */
  const [currentPage, setCurrentPage] = React.useState(1);

  /* Current Studies */
  let session = localStorage.getItem("session")
  session = session && JSON.parse(session)
  let studies = session.user && session.user.studies
  const allData = () => (studies ||[]).sort().map(name => ({ name }));
  
  /* uses CloneDeep Library to set the current collectio of data upon Search */
  const [collection, setCollection] = React.useState(
    cloneDeep(allData().slice(0, countPerPage))
  );

  /* This function searches current data for a matching value */
  /* Makes all words lowercase to prevent case sensitivity */
  /* Calls the above count per page function to limit amount of search results */
  const searchData = React.useRef(
    throttle(val => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        allData()
          .filter(item => item.name.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );

  /* useEffect function declared in order to refresh the table upon queries */
  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  /* When updatePage is called data is sliced into the paginations */
  const updatePage = p => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(allData().slice(from, to)));
  };

  const tableRows = rowData => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return(
      <>
      <Table.Cell textAlign="center"><Icon name="folder" /></Table.Cell>
      <Table.Cell key={i}>{key[keyD]}</Table.Cell>
      {/* Link each cell to breacrumbs /${studyname} */}
      <Table.Cell textAlign="center">
        <Link to={`/studies/${key[keyD]}`}> Details</Link>
      </Table.Cell>
      </>
      );
    });

    /* Return values for rows, each row has an index defined */
    return <Table.Row key={index}>{columnData}</Table.Row>;
  };

  /* Mapping of keys to their individual rows on the table */
  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  /* Returns the column names */
  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <>
      <Table.HeaderCell width={1} textAlign="center"></Table.HeaderCell>
      <Table.HeaderCell textAlign="center" key={index}>{title}</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Details</Table.HeaderCell>
      </>
    ));
  };

  return (
    
    <DashboardLayout>
      <Header as="h2">Studies</Header>
      <div>View and download study data that you have access to.</div>
      <Divider clearing />
      <div className="SearchContainer">
        <div className="SearchinputIcon">
          <center>
            <Icon name="search" size="large"/>
          </center>
        </div>
        <input
          placeholder="Search Campaign"
          value={value}
          className="Searchinput"
          onChange={e => setValue(e.target.value)}
        />
      </div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            {headRow()}
          </Table.Row>
        </Table.Header>
        <Table.Body className="trhover">
              {tableData()}
        </Table.Body>
      </Table>
      <center>
      <div className="pagination">
      <center>
      <Pagination
        pageSize={countPerPage}
        onChange={updatePage}
        current={currentPage}
        total={allData().length}
        locale={locale}
        hideOnSinglePage={true}
        className="pagination"
      />
      </center>
      </div>
      </center>
    </DashboardLayout>
  );
};

export default Studies;
