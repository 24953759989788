import { useEffect, useState, useRef, useContext } from "react";
import { Icon } from "semantic-ui-react";
import MonthPicker from "react-month-picker";
import "../css/Visuals.css";
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400.
import "@fontsource/source-sans-pro";
import "@fontsource/roboto";
import "../css/MonthView.css";
import "react-month-picker/css/month-picker.css";
import { Modal, Button } from "react-bootstrap";
import AppContext from "../AppContext";
import {
  getDateForParticipantUrl,
  getDatesForDatatypes,
  getFileData
} from "../../services/VizualizationService";
import { MonthViewChart } from "./MonthViewChart";
import DayviewAggregate from "./DayView";

import { Tab } from "semantic-ui-react";
import { usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from 'react-loader-spinner';


const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();

  return promiseInProgress &&
    <div
      style={{
        width: "100%",
        height: "300",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <ThreeDots type="ThreeDots" color="#2BAD60" height="100" width="100" />
    </div>
};
export default function MonthView(props) {
  /* Column definition for chart */
  const columns = [
    { type: "string", id: "MetricName" },
    { type: "string", id: "Pass/Fail" },
    { type: "string", id: "style", role: "style" },
    { type: "date", id: "Start" },
    { type: "date", id: "End" },
  ];

  {
    /* Chart Event Listener for Selections */
  }

  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        const HRSSAAdataMappingArray = graphData;
        const chartSelection = chartWrapper.getChart().getSelection();
        const chartSpecific = chartSelection[0];
        const rowSpecific = chartSpecific.row + 1;
        const HRSSAAdataMapping = HRSSAAdataMappingArray[rowSpecific];
        const HRSSAAdataMappingStart = new Date(HRSSAAdataMapping[3]);
        const HRSSdataMappingStartDay = HRSSAAdataMappingStart.getUTCDate() - 1;
        let shiftedDate = new Date((HRSSAAdataMappingStart) * 1 + shiftDate * 24 * 60 * 60000)
        shiftedDate = shiftedDate == 'Invalid Date' ? new Date() : shiftedDate
        let splDate = shiftedDate.toISOString().split('-')
        let day = `${splDate[0]}-${splDate[1]}-${splDate[2].substring(0, 2)}`
        setSelectedDay(day)
        setSelectedNonShiftedDay((`${(HRSSAAdataMappingStart.getMonth() + 1).toString().padStart(2, '0')}-${(HRSSAAdataMappingStart.getDate()).toString().padStart(2, '0')}-${HRSSAAdataMappingStart.getFullYear()}`))
        getFileData(studyName, participant, day, 'hr').then((data) => {
          setHRdata(data)
        })
        getFileData(studyName, participant, day, 'sl').then((data) => {
          setSLdata(data)
        })
        getFileData(studyName, participant, day, 'st').then((data) => {
          setSTdata(data)
        })
        getFileData(studyName, participant, day, 'alert').then((data) => {
          setAlertdata(data)
        })        
        setActivePane(1)
        setHideCalendar(true)

        /* Linke event here needs to be done */
        /*
        const dayData = (
          await getFileData(studyName, participant, parseInt(monthYear.month) || month, parseInt(monthYear.year) || year)
        ).data;
        */

      },
    },
  ];

  /* Render control */
  const [yearState, setYear] = useState();
  const [monthState, setMonth] = useState();
  const [hideCalendar, setHideCalendar] = useState(false);

  
  const [dayState, setDay] = useState();
  const [showMonthViewVisual, setShowMonthViewVisual] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedNonShiftedDay, setSelectedNonShiftedDay] = useState("");
  const [activePane, setActivePane] = useState(0);

  const [graphData, setGraphData] = useState([columns]);
  const [hrData, setHRdata] = useState([columns]);
  const [stData, setSTdata] = useState([columns]);
  const [alertData, setAlertdata] = useState([columns]);
  const [slData, setSLdata] = useState([columns]);
  const [alData, setALdata] = useState([columns]);

  const { shiftDate, setShiftDate } = useContext(AppContext);
  const [nexttimeShift, setnexttimeShift] = useState(shiftDate);
  const [monthYear, setMonthYear] = useState({});

  /* Function call to call Axios upon component mount, then and only then */
  useEffect(async () => {
    await loadData();
  }, [monthYear, shiftDate]);

  const getStartDate = (date) => {
    return (new Date((new Date(date)) * 1 - shiftDate * 24 * 60 * 60000))
    //date.setDate(date.getDate() - shiftDate);
    //return date;
  };

  const getEndDate = (date) => {
    date.setDate(date.getDate() + 1);
    return date;
  };

  const loadData = async () => {
    const response = await getDateForParticipantUrl(studyName, participant);
    const latest_date = new Date(response.data.latest_date || null);
    const month = latest_date.getMonth() + 1;
    const year = latest_date.getFullYear();


    let dt;

    /* Axios Call for Get All Available Dates */

    let shiftedDate;
    let splDate = [year, month]
    if (monthYear.month){
      shiftedDate = new Date((new Date(`${monthYear.month}-01-${monthYear.year}`)) * 1 + shiftDate * 24 * 60 * 60000)
      splDate = shiftedDate.toISOString().split('-')
    }
     

    const graphDates = (
      await getDatesForDatatypes(studyName, participant, parseInt(splDate[1]), parseInt(splDate[0]))
    ).data;

    //graphDates = graphDates.filter(())
    graphDates.hr = graphDates.hr && graphDates.hr.filter((it) => {    
      it = new Date((new Date(it)) * 1 - shiftDate * 24 * 60 * 60000)
      if (!dt || new Date(it) > dt)
        dt = new Date(it)        
      return `${(it.getMonth() + 1).toString().padStart(2, '0')}-${it.getFullYear()}` == getMonthValue() || !monthYear.month
    })

    graphDates.sl = graphDates.sl && graphDates.sl.filter((it) => {
      it = new Date((new Date(it)) * 1 - shiftDate * 24 * 60 * 60000)
      if (!dt || new Date(it) > dt)
      dt = new Date(it)       
      return `${(it.getMonth() + 1).toString().padStart(2, '0')}-${it.getFullYear()}` == getMonthValue() || !monthYear.month
    })

    graphDates.st = graphDates.st && graphDates.st.filter((it) => {
      it = new Date((new Date(it)) * 1 - shiftDate * 24 * 60 * 60000)
      if (!dt || new Date(it) > dt)
      dt = new Date(it)       
      return `${(it.getMonth() + 1).toString().padStart(2, '0')}-${it.getFullYear()}` == getMonthValue() || !monthYear.month
    })

    if (!dt){
      dt = new Date()
    }

    setMonth((dt.getMonth() + 1).toString().padStart(2, '0'));
    setYear(dt.getFullYear());
    setDay(dt.getDate());

    const HRrows = (graphDates.hr && graphDates.hr.map((hrItem) => {
      const startDate = getStartDate(new Date(hrItem));

      return [
        "Heart Rate",
        "",
        "#00B0B9",
        startDate,
        getEndDate(new Date(startDate)),
      ];
    })) || [];

    const Slrows = (graphDates.sl && graphDates.sl.map((slItem) => {
      const startDate = getStartDate(new Date(slItem));

      return [
        "Sleep",
        "",
        "#00B0B9",
        startDate,
        getEndDate(new Date(startDate)),
      ];
    })) || [];

    const Strows = (graphDates.st && graphDates.st.map((stItem) => {
      const startDate = getStartDate(new Date(stItem));

      return [
        "Steps",
        "",
        "#00B0B9",
        startDate,
        getEndDate(new Date(startDate)),
      ];
    })) || [];

    setGraphData([columns, ...HRrows, ...Slrows, ...Strows]);
  };

  /* Breadcrumb definitions */
  const { studyName, participant, onClose } = props;

  /* Define next time shift, navigates to new page, and everything begins again */
  const getTimeShiftValue = (event) => {
    const regexTest = /^[0-9\b]+$/;

    if (event.target.value === "" || regexTest.test(event.target.value)) {
      setnexttimeShift(event.target.value);
    }
  };

  {
    /* Maximum Minimum Mechanisms*/
  }
  const [MaxMin, setMaxMin] = useState("visualizationModalContentsMinimized");
  const [expandIconSizingMaxMin, setexpandIconSizingMaxMin] =
    useState("expandIconSizing");
  const [expandbuttonMaxMin, setexpandbuttonMaxMin] = useState(
    require("../img/expandIcon.png")
  );
  const [modalTitleDescriptorMaxMin, setTitleDescriptorMaxMin] = useState(
    "modalTitleDescriptor"
  );
  const [modalDescriptorContentMaxMin, setmodalDescriptorContentMaxMin] =
    useState("modalDescriptorContent");
  const [flexboxforshiftingMaxMin, setflexboxforshiftingMaxMin] =
    useState("flexboxforshifting");
  const [shiftbarMaxMin, setshiftbarMaxMin] = useState("shiftbar");
  const [shiftDataButtonMaxMin, setshiftDataButtonMaxMin] =
    useState("shiftDataButton");
  const [undoButtonMaxMin, setundoButtonMaxMin] = useState("undoButton");
  const [visualMaxMin, setvisualMaxMin] = useState("flexboxforvisualization");

  const changeMaxMin = () => {
    setMaxMin(() =>
      MaxMin === "visualizationModalContentsMaximized"
        ? "visualizationModalContentsMinimized"
        : "visualizationModalContentsMaximized"
    );
    setexpandIconSizingMaxMin(() =>
      expandIconSizingMaxMin === "expandIconSizing"
        ? "expandIconSizingMax"
        : "expandIconSizing"
    );
    setexpandbuttonMaxMin(() =>
      expandbuttonMaxMin === require("../img/expandIcon.png")
        ? require("../img/minimizeIcon.png")
        : require("../img/expandIcon.png")
    );
    setTitleDescriptorMaxMin(() =>
      modalTitleDescriptorMaxMin === "modalTitleDescriptor"
        ? "modalTitleDescriptorMax"
        : "modalTitleDescriptor"
    );
    setmodalDescriptorContentMaxMin(() =>
      modalDescriptorContentMaxMin === "modalDescriptorContent"
        ? "modalDescriptorContentMax"
        : "modalDescriptorContent"
    );
    setflexboxforshiftingMaxMin(() =>
      flexboxforshiftingMaxMin === "flexboxforshifting"
        ? "flexboxforshiftingMax"
        : "flexboxforshifting"
    );
    setshiftbarMaxMin(() =>
      shiftbarMaxMin === "shiftbar" ? "shiftbarMax" : "shiftbar"
    );
    setshiftDataButtonMaxMin(() =>
      shiftDataButtonMaxMin === "shiftDataButton"
        ? "shiftDataButtonMax"
        : "shiftDataButton"
    );
    setundoButtonMaxMin(() =>
      undoButtonMaxMin === "undoButton" ? "undoButtonMax" : "undoButton"
    );
    setvisualMaxMin(() =>
      visualMaxMin === "flexboxforvisualizationMax"
        ? "flexboxforvisualization"
        : "flexboxforvisualizationMax"
    );
  };

  const [value, setValue] = useState({
    year: yearState - Math.round(shiftDate / 365),
    month: monthState,
  });
  const monthPickerRef = useRef(null);

  const showPicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.show();
    }
  };

  const hidePicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.dismiss();
    }
  };

  const handleOnChange = async (year, month) => {
    hidePicker();
    setMonthYear({ year: year, month: (parseInt(month)).toString() });
    setActivePane(0)
    setSelectedNonShiftedDay('')

  };

  const getMonthValue = () => {
    let month = '';
    let year = '';
    let shift = shiftDate

    month = (monthYear.month || monthState)
    year = (monthYear.year || yearState)

    let shiftedDate = new Date((new Date(`${month}-${dayState || '01'}-${year}`)) * 1)
    shiftedDate = shiftedDate == 'Invalid Date' ? new Date() : shiftedDate
    let splDate = shiftedDate.toISOString().split('-')

    return `${splDate[1]}-${splDate[0]}`
  };


  const getValue = () => {
    let month = '';
    let year = '';
    let shift = shiftDate

    month = (monthYear.month || monthState)
    year = (monthYear.year || yearState)

    let shiftedDate = new Date((new Date(`${month}-${dayState || '01'}-${year}`)) * 1)
    shiftedDate = shiftedDate == 'Invalid Date' ? new Date() : shiftedDate
    let splDate = shiftedDate.toISOString().split('-')
    //console.log(splDate)

    return { "year": parseInt(splDate[0]), "month": parseInt(splDate[1]) -2  }
  };

  const handleShiftDataClick = async (e) => {
    e.preventDefault();
    setShiftDate(nexttimeShift);
  };

  const lang = {
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };

  const panes = [
    {
      menuItem: "Month View  -> ",
      render: () => (
        <Tab.Pane loading={(graphData.length > 0 ? false : true)}>
          {graphData.length > 1 &&
            <>
              <MonthViewChart data={graphData} chartEvents={chartEvents} />
            </>
          }
        </Tab.Pane>
      ),
    },
    {
      menuItem: `Day View -> ${selectedNonShiftedDay || 'Not Selected'}`,
      render: () => (
        <Tab.Pane loading={graphData.length > 1 ? false : true}>
          <div>
            <DayviewAggregate shiftDate={selectedDay} hrData={hrData} slData={slData} stData={stData} alertData={alertData} />
          </div>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Modal show={true} animation={false}>
      <Modal.Body>

        <div className="visualizationModalBackdrop">
          <div className={MaxMin}>
            <div>
              <a
                href="#"
                className="closeButtonStyling"
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
              >
                <Icon name="close" size="large" />
              </a>
            </div>
            <div className="visualizationModalExpandButton">
              <Button onClick={changeMaxMin}>
                <Icon name="expand arrows alternate" />
              </Button>
            </div>
            <div className={modalTitleDescriptorMaxMin}>
              Visualize Data for Participant: {participant}
            </div>
            <div className={modalDescriptorContentMaxMin}>
              Get visual summary of research participants' health data
            </div>
            <div className={flexboxforshiftingMaxMin}>
              <input
                className={shiftbarMaxMin}
                onChange={getTimeShiftValue}
                defaultValue={shiftDate}
              ></input>
              <a
                href="#"
                className={shiftDataButtonMaxMin}
                onClick={handleShiftDataClick}
              >
                Shift Data
              </a>
            </div>
            <div className={visualMaxMin}>
              <div className="timeselectionBox2">
                <div className="floatvisualnavbuttons" hidden={hideCalendar}>
                  <MonthPicker
                    lang={lang.months}
                    ref={monthPickerRef}
                    years={{ min: 2013, max: 2050 }}
                    value={getValue()}
                    onChange={handleOnChange}
                  >
                    <button className="calendarModal" onClick={showPicker}>
                      {getMonthValue()}
                    </button>
                  </MonthPicker>
                </div>
              </div>
              <div className="TabbedMenuForVisulisation">
                <Tab onTabChange={(event, data) => {
                  if (data.activeIndex ==0) setHideCalendar(false)
                  else setHideCalendar(true)
                  setActivePane(data.activeIndex)
                  }} renderActiveOnly={true} menu={{ text: true }} panes={panes} activeIndex={activePane || 0} />
              </div>
              <LoadingIndicator />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
