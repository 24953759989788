import React, {useContext} from 'react';
import DashboardLayout from "../components/DashboardLayout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Header, Breadcrumb, Divider, Icon, Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import axios from "axios";
import cognitoUtils from '../lib/cognitoUtils'

/* RC-Pagination Import */
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import { trackPromise } from 'react-promise-tracker';
/* Styling */
import './css/Studies.css' 
import "rc-pagination/assets/index.css";
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400.  
import "@fontsource/source-sans-pro";
import "@fontsource/roboto";

/* Remove weird Korean Symbols */
import locale from 'rc-pagination/es/locale/en_US';
import VisualizationPromptModal from '../components/Visualizations/VisualizationPromptModal';
import MonthView from '../components/Visualizations/MonthView';
import AppContext from '../components/AppContext';
import { IconLaptopWindows } from '@aws-amplify/ui-react';
import { usePromiseTracker } from "react-promise-tracker";
import {ThreeDots} from 'react-loader-spinner';
import appConfig from '../config/app-config.json'

/* Column Names for the top of the table, only needs Study name to be dynamic */
const tableHead = {
  name: "Participant ID"
};

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();

  return promiseInProgress && 
    <div
      style={{
        width: "100%",
        height: "300",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <ThreeDots type="ThreeDots" color="#2BAD60" height="100" width="100" />
    </div>
};
export default function StudyDetails() {

  /* Current Studies */
  let allData = [];

  /* Render control */
  const [participantId, setParticipantId] = useState(null);
  const { setShiftDate } = useContext(AppContext);

  const {shiftDate} = useContext(AppContext);
  const [showTable, setShowTable] = useState('');
  const handleShowTable = () => setShowTable(<StudiesDetailsTable />);

  const StudiesDetailsTable = () => {
    /* Value declaration for Pagination Max values */
    const countPerPage = 8;

    /* Value Declaration of the value for the Search function */
    const [value, setValue] = React.useState("");

    /* Value Declaration for the current Page for pagination */
    const [currentPage, setCurrentPage] = React.useState(1);

    /* uses CloneDeep Library to set the current collectio of data upon Search */
    const [collection, setCollection] = React.useState(
      cloneDeep(allData.slice(0, countPerPage))
    );

    /* This function searches current data for a matching value */
    /* Makes all words lowercase to prevent case sensitivity */
    /* Calls the above count per page function to limit amount of search results */
    const searchData = React.useRef(
      throttle(val => {
        const query = val.toLowerCase();
        setCurrentPage(1);
        const data = cloneDeep(
          allData
            .filter(item => item.name.toLowerCase().indexOf(query) > -1)
            .slice(0, countPerPage)
        );
        setCollection(data);
      }, 400)
    );

    /* useEffect function declared in order to refresh the table upon queries */
    React.useEffect(() => {
      if (!value) {
        updatePage(1);
      } else {
        searchData.current(value);
      }
    }, [value]);

    /* When updatePage is called data is sliced into the paginations */
    const updatePage = p => {
      setCurrentPage(p);
      const to = countPerPage * p;
      const from = to - countPerPage;
      setCollection(cloneDeep(allData.slice(from, to)));
    };

    const tableRows = rowData => {
      const { key, index } = rowData;
      const tableCell = Object.keys(tableHead);
      const columnData = tableCell.map((keyD, i) => {
        return(
          <>
          <Table.Cell textAlign="center"><Icon name="folder" /></Table.Cell>
          <Table.Cell key={i}>{key[keyD]}</Table.Cell>
          {/* Link each cell to breacrumbs /${studyname} */}
          <Table.Cell textAlign="center">
            <a href='#' onClick={(e) => {e.preventDefault(); setParticipantId(key[keyD])}}>
              <Icon name="line graph" />
            </a>
          </Table.Cell>
        </>
       );
      });
  
    /* Return values for rows, each row has an index defined */
      return <Table.Row key={index}>{columnData}</Table.Row>;
    };
  
    /* Mapping of keys to their individual rows on the table */
    const tableData = () => {
      return collection.map((key, index) => tableRows({ key, index }));
    };

    /* Returns the column names */
    const headRow = () => {
      return Object.values(tableHead).map((title, index) => (
        <>
        <Table.HeaderCell width={1} textAlign="center"></Table.HeaderCell>
        <Table.HeaderCell textAlign="center" key={index}>{title}</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Visualize</Table.HeaderCell>
        </>
      ));
    };

    return (
      /* Overhead container to prevent JSX fragments */
      <div>
        <div className="SearchContainer">
          <div className="SearchinputIcon">
            <center>
              <Icon name="search" size="large"/>
            </center>
          </div>
          <input
            placeholder="Search Campaign"
            value={value}
            className="Searchinput"
            onChange={e => setValue(e.target.value)}
          />
        </div>
        <Table celled>
          <Table.Header>
            <Table.Row>
              {headRow()}
            </Table.Row>
          </Table.Header>
          <Table.Body className="trhover">
            {tableData()}
          </Table.Body>
        </Table>
        <center>
          <div className="pagination">
            <center>
              <Pagination
                pageSize={countPerPage}
                onChange={updatePage}
                current={currentPage}
                total={allData.length}
                locale={locale}
                hideOnSinglePage={true}
                className="pagination"
              />
            </center>
          </div>
        </center>
      </div>
    )
  } 

  /* Function call to call Axios upon component mount, then and only then */
  useEffect(() => {
    getAllStudyDetails();
  }, []);

  /* Return Statement, renders upon completion of all Operators */
  /* Breadcrumb constant from last Page */
  const { studyName } = useParams();
  let session = localStorage.getItem("session")
  session = session && JSON.parse(session)
  let idToken = session.credentials.idToken;
  const getAllStudyDetails = () => {
    const url = `${appConfig.dataAPIUri}/${studyName}/participants/ids`;
    trackPromise(
    axios.get(url, {
      headers: {"Authorization": `Bearer ${idToken}`}
    }).then((response) => {
        const data = response.data.participants;
        const n = 1;
        const totalParticipants = Math.ceil(data.length / 1);
        for (let entry= 0; entry < n; entry++) {
          for (let i = 0; i < totalParticipants; i++) {
            const value = data[i + entry * totalParticipants]
            if (!value) continue
            allData.push({
              name: response.data.participants[i],
            })
        }
      }
      handleShowTable();
    })
  .catch((error) => {
    console.error(`Error: ${error}`)
    if (error.response.status == 401)
      cognitoUtils.signOutCognitoSession();
      
  }));
  }

  return(
    <DashboardLayout>
      <Header as="h2">{studyName} Study</Header>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Link to="/studies">Studies</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{studyName}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider hidden />
        <div>
          Discover trends and patterns in individual data from {studyName}.
        </div>
      <Divider />
      <LoadingIndicator/>            
      {showTable ? showTable : ''}
      {participantId && !shiftDate ? <VisualizationPromptModal participant={participantId} onClose={() => setParticipantId(null)} /> : ''}
      {participantId && shiftDate ? <MonthView studyName={studyName} participant={participantId} onClose={() => {setParticipantId(null); setShiftDate('');}} /> : ''}
    </DashboardLayout>
  )
}
