import axios from 'axios';
import cognitoUtils from '../lib/cognitoUtils'
import { trackPromise } from 'react-promise-tracker';
import appConfig from '../config/app-config.json'

const apiURL = `${appConfig.dataAPIUri}`;

export async function getDateForParticipantUrl(studyName, participantId) {
  let session = localStorage.getItem("session")
  session = session && JSON.parse(session)
  let idToken = session.credentials.idToken;

  return  trackPromise(axios.post(`${apiURL}/${studyName}/participants/latest-date`, {
    participant_id: participantId
  }, {
    headers: {"Authorization": `Bearer ${idToken}`}
  }).catch((error) => {
    console.error(`Error: ${error}`)
    if (error.response.status == 401)
      cognitoUtils.signOutCognitoSession();
      
  }));
}

export async function getDatesForDatatypes(studyName, participantId, month, year) {
  let session = localStorage.getItem("session")
  session = session && JSON.parse(session)
  let idToken = session.credentials.idToken;

  return  trackPromise(axios.post(`${apiURL}/${studyName}/dates-for-datatypes`, {
    participant_id: participantId,
    month,
    year
  }, {
    headers: {"Authorization": `Bearer ${idToken}`}
  }).catch((error) => {
    console.error(`Error: ${error}`)
    if (error.response.status == 401)
      cognitoUtils.signOutCognitoSession();
      
  }));
}

export async function getFileData(studyName, participantId, shiftDate, type) {
  let session = localStorage.getItem("session")
  session = session && JSON.parse(session)
  let idToken = session.credentials.idToken;

  return  trackPromise(axios.post(`${apiURL}/${studyName}/data`, {
    participant_id: participantId,
    type: type,
    dateShift: shiftDate
  }, {
    headers: {"Authorization": `Bearer ${idToken}`}
  }).then((resp)=>{
    return resp.data[type]
  }).catch((error) => {
    console.error(`Error: ${error}`)
    if (error.response.status == 401)
      cognitoUtils.signOutCognitoSession();
      
  }));
}