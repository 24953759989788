import React, { useState } from "react";
import Login from "./pages/Login";
import { Routes, Route } from "react-router-dom";
import Studies from "./pages/Studies";
import SupportModal from "./components/SupportModal";
import StudyDetails from "./pages/StudyDetails";
import NotFound from "./components/NotFound";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import AppContext from "./components/AppContext";
import Callback from "./routes/Callback"
import Cognito from "./routes/Cognito"
import { useSearchParams } from "react-router-dom";
import "./App.css";
import { trackPromise } from 'react-promise-tracker';
function App() {
  const [shiftDate, setShiftDate] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();

  const shiftDateSettings = {
    shiftDate,
    setShiftDate,
  };

  return (
    <AppContext.Provider value={shiftDateSettings}>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path='login' element={<Cognito/>}/>              
          <Route path='callback' element={<Callback searchParams={searchParams}/>}/>             

          {/* protected routes */}
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Studies />} />
            <Route path="#" element={<SupportModal />} />
            <Route path="studies" element={<Studies />} />
            <Route path="studies/:studyName" element={<StudyDetails />} />
          </Route>
          {/* catch all */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );
}
export default App;
