import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";

//Dashboard imports
import "../../css/Dashboard.css";
import "../../css/topnav.css";
import "../../css/Studies.css";
import "../../css/Sidenav.css";
import "../css/Visuals.css";

import { Modal } from "react-bootstrap";

import { useEffect } from "react";

//Font imports
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400.
import "@fontsource/source-sans-pro";
import "@fontsource/roboto";

import axios from "axios";
import AppContext from "../AppContext";

/* Window Location reload */
function reloadPage() {
  setTimeout(function () {
    window.location.reload(1);
  }, 250);
}

export default function VisualizationPromptModal(props) {
  /* Breadcrumb definition */
  const { participant, onClose } = props;

  const [latest_date_month, setlatest_date_month] = useState(0);
  const [latest_date_year, setlatest_date_year] = useState(0);
  const [timeShift, settimeShift] = useState(0);
  const { setShiftDate } = useContext(AppContext);

  {
    /*OnClick Handlers*/
  }
  const handleCloseVisualizationPrompt = () => {
    setShiftDate(timeShift);
    settimeShift(0);
  };

  const getTimeShiftValue = (event) => {
    //Test strange issue with allowing text in numerical field regex
    const regexTest = /^[0-9\b]+$/;

    if (event.target.value === "" || regexTest.test(event.target.value)) {
      settimeShift(event.target.value);
    }
  };

  return (
    <div className="scrolling">
      {/* Initial TimeShift Prompt Modal */}
      <Modal show={true} animation={false}>
        <Modal.Body>
          <div className="modal-backdrop">
            <div className="modal-contents2">
              <div className="closePositioning">
                <div className="closeButtonStyling" onClick={onClose}>
                  <Icon name="close" size="large" />
                </div>
              </div>
              <div className="modalTitleDescriptor2">
                Visualize Data for Participant: {participant}
              </div>
              <div className="modalContentDescription">
                The data is visualized with dates shifted to the future by
                default. Please provide the number of days you prefer the system
                to shift the dates back to the present. You could edit this
                number later.
              </div>
              <div>
                <input
                  type="number"
                  className="shiftbar2"
                  onChange={getTimeShiftValue}
                  placeholder="Type in the number of days without a comma."
                ></input>
              </div>
              <div>
                <button
                  onClick={handleCloseVisualizationPrompt}
                  className="shiftDataButton2"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
