import "./../css/Sidenav.css";
import React, { useContext } from "react";
import "@fontsource/ibm-plex-sans";
import { Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { AuthProviderContext } from "../auth/auth-provider";
import SupportModal from "../components/SupportModal";
import cognitoUtils from '../lib/cognitoUtils'


//Font imports
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400.
import "@fontsource/source-sans-pro";
import "@fontsource/roboto";

export default function DashboardLayout({ children }) {
  let session = localStorage.getItem("session")
  session = session && JSON.parse(session)  
  let userId =  session.user.email

  return (
    <div className="fillWindow">
      <div className="sidebar">
        <img className="logoSizing" src={require("../images/logo.png")}></img>
        <div className="navLinks">
          <NavLink
            to="/studies"
            className={(isActive) => (isActive ? "active" : "")}
          >
            <Icon name="folder" className="navImageSizing" />
            Studies
          </NavLink>
          <SupportModal />
        </div>
      </div>
      <div className="topnav">
        <span className="user-name">
          {userId ? userId : "Please log back in"}
        </span>
        <div className="headerDivider"></div>
        <a onClick={cognitoUtils.signOutCognitoSession} className="pointer">
          Logout <Icon name="sign-out" />
        </a>
      </div>
      <div className="rightSide">{children}</div>
    </div>
  );
}
