import React from 'react';
import '../../css/Visuals.css';
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400.  
import "@fontsource/source-sans-pro";
import "@fontsource/roboto";
import { PureComponent } from 'react';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Sector, ResponsiveContainer, PieChart, Pie } from "recharts";


const average = array => {
    array = array.filter(it => it && it.Value && !isNaN(it.Value))
    array = array.map((it) => it.Value)
    if (array.length > 0) {
        return array.reduce((a, b) => {
            return (a + b)
        }) / array.length;
    } else return ''
}
const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 5) * cos;
    const sy = cy + (outerRadius + 5) * sin;
    const mx = cx + (outerRadius + 10) * cos;
    const my = cy + (outerRadius + 10) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};
export default class Sleep extends PureComponent {



    state = {
        activeIndex: 0,
    };

    onPieEnter = (_, index) => {
        this.setState({
            activeIndex: index,
        });
    };
    render() {
        let slData = this.props.slData || []
        let typeObj = {}
        if (Array.isArray(slData)) {
            slData = slData.map((it) => {
                it['Start_Time'] = it['Start_Time'] && it['Start_Time'].replace('.000', '')
                if (!isNaN(it.Value)) {
                    if (typeObj[it.Type]) {
                        typeObj[it.Type] = typeObj[it.Type] + it.Value
                    } else {
                        typeObj[it.Type] = it.Value
                    }
                }

                return it
            })
        }

        let data = []

        Object.keys(typeObj).map(((key)=>{
            data.push({ name: key, value: typeObj[key] })
        }))


        return (
            <>
                <div className="statsBox">
                    <div className="averageContainer">
                        <div className="statsAverage"> Sleep</div>
                    </div>
                    <div className="lineStats"></div>
                    <div className="descriptorsContainer">
                        <div className="averageDescriptor">Sleep Cycles</div>
                        <div className="descriptor2">Device: {slData[0] && slData[0].Device}</div>
                    </div>
                </div>
                <div style={{ width: "100%", height: "100%", backgroundColor: "white" }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={300} height={300}>
                            <Pie
                                activeIndex={this.state.activeIndex}
                                activeShape={renderActiveShape}
                                data={data}
                                cx="65%"
                                cy="50%"
                                innerRadius={50}
                                outerRadius={70}
                                fill="#8884d8"
                                dataKey="value"
                                onMouseEnter={this.onPieEnter}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }
}
