import axios from 'axios';

export async function submitSupportForm(email, message) {
  try {
    await axios.post(`https://tkhiquyaopsxwawwfor2nozcly0oqork.lambda-url.us-east-1.on.aws/`, {
      from: email,
      msg: message
    });

    return true;
  } catch(e) {
    // TODO: maybe return the error message, or create an error message to send back to the user
    return false;
  }
}
