import React, { Component } from 'react'
import { connect } from 'react-redux'
import { initSessionFromCallbackURI } from '../actions/session'
import appConfig from '../config/app-config.json'

function mapStateToProps (state) {
  return { session: state.session }
}
function mapDispatchToProps (dispatch) {
  return {
    initSessionFromCallbackURI: href => dispatch(initSessionFromCallbackURI(href))
  }
}


/**
  Callback route used after a successful Cognito sign-in. The window URL will contain the code we can
  use to get a Cognito session, which includes JWT tokens etc
 */
class Cognito extends Component {
  // If a Cognito auth code is in the URL (could be a hash or query component), init the new session
  componentDidMount () {

  }

  render () {

      let redirectUri = `${appConfig.userPoolBaseUri}/login?response_type=code&client_id=${appConfig.clientId}&redirect_uri=${appConfig.callbackUri}`;
      //alert(redirectUri)
      window.location.href = redirectUri; 

    return <div />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cognito)
