import React from "react";
import {
  Button,
  Header,
  Modal,
  Icon,
  Form,
  TextArea,
  Divider,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import "./../css/Sidenav.css";
import { submitSupportForm } from "../services/SupportService";

export default function SupportModal() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [message, setMessage] = React.useState();

  const onSubmit = async (event) => {
    event.preventDefault();
    const response = await submitSupportForm(email, message);

    if (response) {
      setEmail(null);
      setMessage(null);
      setOpen(false);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeIcon
      open={open}
      trigger={
        <NavLink to="#" className={(isActive) => (isActive ? "active" : "")}>
          <Icon name="question" />
          Support
        </NavLink>
      }
    >
      <Modal.Content>
        <Modal.Description>
          <Header>Support</Header>
          <p>Get in touch with customer support team.</p>
        </Modal.Description>
        <Divider hidden />
        <Form onSubmit={onSubmit}>
          <Form.Field
            name="email"
            control="input"
            label="Email"
            placeholder="synopsis of your issue"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <Form.Field
            name="message"
            control={TextArea}
            label="Message"
            placeholder="How can we help?"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          <Button type="submit">Submit</Button>
        </Form>
      </Modal.Content>
      {/* <Modal.Actions>
        <Button
          content="Submit"
          labelPosition="right"
          icon="checkmark"
          onClick={() => setOpen(false)}
          color="red"
        />
      </Modal.Actions> */}
    </Modal>
  );
}

{
  /* Fixed weird routing issue that logged users out when clicking the Navlink for this in sidenav */
}
