import { CLEAR_SESSION, SET_SESSION } from '../constants/actionTypes'
let localStorage = require('localStorage');

const initialState = {
  isLoggedIn: false
}



const session = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      let session = Object.assign({},
        action.session,
        { isLoggedIn: true });
      localStorage.setItem('session', JSON.stringify(session));
      return session;

    case CLEAR_SESSION:
      return initialState

    default: {
      let localSession = JSON.parse(localStorage.getItem('session'));
      return localSession || state;
    }
  }
}

export default session
