import React from 'react';
import '../../css/Visuals.css';
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400.  
import "@fontsource/source-sans-pro";
import "@fontsource/roboto";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
let data = require('../../../DataHandlers/Fitbitsample.json');

const total = array => {
    array = array.filter(it => it && it.Value && !isNaN(it.Value))
    array = array.map((it) => it.Value)
    if (array.length > 0) {
        return array.reduce((a, b) => {
            return (a + b)
        })
    }
    else
        return 0

}

function Steps(props) {
    let stData = props.stData || []
    stData = stData.map((it)=>{
        if (it.Start_Time){
            let time = it.Start_Time.split(':')
            time.pop()
            it.x = time.join(':')
        }
        return it
    })    
    let sum = total(stData)
    return (
        <>
            <div className="statsBox">
                <div className="averageContainer">
                    <div className="statsAverage">{sum}</div>
                    <div className="averageUnit"> Steps</div>
                </div>
                <div className="lineStats"></div>
                <div className="descriptorsContainer">
                    <div className="averageDescriptor">Steps</div>
                    <div className="descriptor2">Device: {stData[0] && stData[0].Device}</div>
                </div>
            </div>
            <div style={{ width: "100%", height: "100%", backgroundColor: "white" }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart width={800} height={300} data={stData} margin={{ top: 90, right: 30, left: 20, bottom: 0, }}>
                        <CartesianGrid vertical={false} stroke="#C0C0BF" />
                        <XAxis dy={10} dataKey="x" stroke="#C0C0BF" tickLine={false} />
                        <YAxis dx={-22} axisLine={true} />
                        <Tooltip />
                        <Line type="monotone" dataKey="Value" strokeWidth={2} dot={false} stroke="#009AB4" activeDot={{ r: 1 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </>
    );
}
export default Steps;