import React, { createContext, useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../UserPool";

const AuthProviderContext = createContext();

const AuthProvider = (props) => {
  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        });
      } else {
       // reject();
      }
    });

  const auth = async (Username, Password) =>
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("onSuccess:", data);
          resolve(data);
        },

        onFailure: (err) => {
          console.error("onFailure:", err);
          reject(err);
        },

        newPasswordRequired: (data) => {
          console.log("newPasswordRequired:", data);
          resolve(data);
        },
      });
    });

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      console.log("signout")
      localStorage.clear();
      user.signOut();
    }
    window.location.replace('/login');
  };

  // Set Users Name in the context
  const [usersName, setName] = useState("");
  async function getUsersData() {
    getSession().then((session) => {
      setName(session.idToken.payload.name);
    });
  }
  getUsersData();

  return (
    <AuthProviderContext.Provider
      value={{
        auth,
        getSession,
        usersName,
        logout,
      }}
    >
      {props.children}
    </AuthProviderContext.Provider>
  );
};

export { AuthProvider, AuthProviderContext };
