import React from "react";
import "../../css/Visuals.css";
import "@fontsource/ibm-plex-sans"; // Defaults to weight 400.
import "@fontsource/source-sans-pro";
import "@fontsource/roboto";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Icon } from "semantic-ui-react";
let data = require("../../../DataHandlers/Fitbitsample.json");

const average = array => {
  array = array.filter(it => it && it.Value && !isNaN(it.Value))
  array = array.map((it)=>it.Value)
  return array.reduce((a, b) => {
      return (a + b)
  }) / array.length;
}

function AlgorithmAlerts(props) {
  let alertData = props.alertData || []
  let isRed = alertData.filter((it)=>{
    return it.Alert && it.Alert.toUpperCase() != 'GREEN' 
  }).length

  return (
    <>
      <div className="statsBox">
        <div className="averageContainer">
          <div className="statsAverage">Alert</div>
        </div>
        <div className="lineStats"></div>
        <div className="descriptorsContainer">
          <div className="averageDescriptor"></div>
          <div className="descriptor2"></div>
        </div>
      </div>
      <div className="alertIcon" style={{ backgroundColor: "white" }}>
        {isRed == 0 ? (
          <>
            <Icon color="green" size="huge" name="check circle" />
            <p>
              No alerts on this day
            </p>
          </>
        ) : (
          <>
            <Icon color="red" size="huge" name="exclamation triangle" />
            <p>
              Some changes are detected in the physiological pattern for this
              date.
            </p>
          </>
        )}
      </div>
    </>
  );
}
export default AlgorithmAlerts;

/*  */
