import { useLocation, Navigate, Outlet } from "react-router-dom";
import React, { useContext } from "react";
import { AuthProviderContext } from "../auth/auth-provider";
import useAuth from "../hooks/useAuth";
let localStorage = require('localStorage');

const RequireAuth = () => {
  const { usersName } = useAuth(AuthProviderContext);
  let session = localStorage.getItem('session')
  session = session && JSON.parse(session)
  const isAuthenticated = session && session.isLoggedIn;

  const location = useLocation();

  if ( isAuthenticated ) {
    return true ? (
      <Outlet />
    ) : (
      <Navigate to="/Studies" state={{ from: location }} replace />
    );
  }

  if ( !isAuthenticated ) {
    return false ? (
      <Outlet />
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
};

export default RequireAuth;
