import {Chart} from 'react-google-charts';

export const MonthViewChart = (props) => {
  // Options to control the graph
  const HRSSAAoptions = {
    hAxis: {
      format: "dd",
      baselineColor: 'none',
    },
    allowHtml: true, /* Allowed Html for custom tooltip */
    timeline: {
      groupByRowLabel: true,
      showRowLabels: true, /* Hide Row Labels */
      showBarLabels: false, /* Disabled but declared for resizing */
      avoidOverlappingGridLines: false, /* Allow boxes to be next to each other with 0 padding */
      rowLabelStyle: {
        fontName: 'Roboto',
        fontSize: 16,
        color: '#6D6C69',
      },
      barLabelStyle: {
        fontSize: 8
        /* This controls the bar height for the component */
      },
    },
    backgroundColor: "#ffffff",
    alternatingRowStyle: false,
  };

  return(
    <Chart
      chartType="Timeline"
      options={HRSSAAoptions}
      data={props.data}
      width="100%"
      height="100%"
      chartEvents={props.chartEvents}
    />
  )
}
